import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@mui/material'
import { toast } from 'react-toastify'
const ConfigDelete = ({
  deleteModal,
  setDeleteModal,
  pagination,
  search,
  selectedContent,
  isSubmitting,
  setIsSubmitting,
  DeleteConfigAction,
  configFetchedWithPagination

}) => {
  /* =========Delete UsecaseApi implementation  ========= */
  const handleDeleteConfig = () => {
    setIsSubmitting(true)
   DeleteConfigAction(selectedContent['use-case-id'])
      .then(response => {
        if (response?.status === 200) {
          toast.success('Usecase deleted successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          })
          setIsSubmitting(false)
          setDeleteModal(false)
      configFetchedWithPagination(pagination, search)
          return Promise.resolve()
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          })
          setIsSubmitting(false)
          return Promise.reject()
        }
      })
      .catch(error => {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
        setIsSubmitting(false)
        setDeleteModal(false)
        return Promise.reject()
      })
  }
  return (
    <>
      {/* <!--- Delete Model Box ---> */}
      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'bold', fontSize: '1.1em' }}>
            Do you want to delete
            <span style={{ color: 'red', marginLeft: '3px' }}>
              {selectedContent?.['use-case-name']}
            </span>{' '}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteConfig()}
            variant="contained"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
          >
            Yes
          </Button>
          <Button onClick={() => setDeleteModal(false)} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfigDelete
