import __env from '../../../env'
import instance from '../../../middleware/axiosInstance'
import axios from 'axios'

export const fetchConfig = () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/schema/all`)
}

export const fetchConfigWithPagination = (pageable,search) => {
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/config/page?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&searchBy=${search}`
  )
}

export const createConfig = data => {
  return axios.post(`${process.env.REACT_APP_SCHEMA_URL}/admin/config`, data)
}

export const createWorkflow = (videoConfig,data) => {
  const url = `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/create`
  const params = new URLSearchParams({
    'use-case': data['use-case'],
    'sub-use-case': data['sub-use-case'],
    'template-id': data['template-id'],
    'use-case-type':data['use-case-type']
  });
  return axios.post(`${url}?${params.toString()}`, videoConfig)
}

export const updateConfig = (id, data) => {
  return axios.put(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/config/${id}`,
    data
  )
}

export const fetchConfigById = id => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/config/${id}`)
}

export const deleteConfig = id => {
  return instance.delete(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/config/${id}`
  )
}

export const deleteSchema = id => {
  return instance.delete(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/schema/${id}`
  )
}

export const uploadUseCaseFiles = data => {
  return axios.post(`${process.env.REACT_APP_SCHEMA_URL}/admin/upload/`, data)
}

export const kdenUpload = (data, options = {}) => {
  return axios.post(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/kden/upload`,
    data,
    {
      onUploadProgress: options.onUploadProgress,
    }
  )
}

export const fetchMood = () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/mood`)
}

export const fetchLanguage= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/language`)
}

export const fetchTransliterationType= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/type`)
}

export const fetchLipsyncSourceVideoPath= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/source-video-path`)
}

export const fetchLipsyncPretrained= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/pretrained-path`)
}

export const fetchLipsynOpenface= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/landmark-path`)
}

export const fetchStyle= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/style`)
}

export const fetchModels= (gender,language) => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/models_by_gender_and_language?language=${language}&gender=${gender}`)
}

export const fetchVcModel= () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/list/vc_model`)
}


export const downloadKdenCsv= (templateId) => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/kden/download-csv/${templateId}`)
}

export const ChangeConfigStatus = (id) => {
  return instance.put(`${process.env.REACT_APP_CACHE_APPROVE_URL}/admin/config/change/status/${id}`
)
}

export const downloadUsecaseCsv = (id) => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/csv/concat-csv?useCaseId=${id}`)
}

export const CsvUpload = (data) => {
  return axios.post(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/upload/csv`,data)
}

export const getFinalVideosCount = (useCaseName) => {
  return instance.get(`${process.env.REACT_APP_CACHE_URL}/cache/request/get/count?useCaseName=${useCaseName}`)
}

export const downloadFinalVideosCsv = useCaseId => {
  return instance.get(
    `${process.env.REACT_APP_GLOBAL_CACHE_URL}/cache/request/download/videos/useCaseId=${useCaseId}`
  )
}

export const cacheTransliteration = (data) => {
  return axios.post(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/vc/upload?prefix-audio-link=${data['prefix-audio-link']}&suffix-audio-link=${data['suffix-audio-link']}&prefix-user-text=${data['prefix-user-text']}&suffix-user-text=${data['suffix-user-text']}&prefix-source-audio-text=${data['prefix-source-audio-text']}&suffix-source-audio-text=${data['suffix-source-audio-text']}`
  )
}

export const startUsecase = useCaseId => {
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/config/start/${useCaseId}`
  )
}

export const stopUsecase = useCaseId => {
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/config/stop/${useCaseId}`
  )
}


export const getFontNames = () => {
  return instance.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/font/all`)
}

export const workflowStateListing = (data) => {
  return instance.post(
    `${process.env.REACT_APP_CACHE_URL}/cache/workflow/get-states`,data
  )
}

export const getWorkflowStateById = (id) => {
  return instance.get(
    `${process.env.REACT_APP_CACHE_URL}/cache/workflow/${id}`,id
  )
}

export const deleteWorkflowState = id => {
  return instance.delete(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/config/${id}`
  )
}