import { createSlice } from '@reduxjs/toolkit'
import {
    defaultPage,
    defaultSize,
    defaultSortDir,
    defaultTotalElements,
    defaultSortBy,
    defaultTotalPages,
} from '../../../constants/index'
import { cacheWorklowPartsStatus } from './dagCrud'

const initialState = {
    getDag: [],
    dagPagination: [],
    workflowCountPagination:[],
    workflowMltCount:[],
    cacheWorklowPartsStatus:[],
    getCounts:[],
    getKdenCounts:null,
    getSingleDag:[],
    pageable: {
        pageNo: defaultPage,
        pageSize: defaultSize,
        sortBy: defaultSortBy,
        sortDir: defaultSortDir,
        totalPages: defaultTotalPages,
        totalElements: defaultTotalElements,
    },
}

const dagSlice = createSlice({
    name: 'dag',
    initialState: initialState,
    reducers: {
      dagFetched: (state, action) => {
            state.getDag= action.payload
        },
        dagPaginationFetched: (state, action) => {
            state.dagPagination = action.payload.content
            state.pageable = {
                ...state.pageable,
                pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
            }
        },

        workflowMltPaginationFetched: (state, action) => {
            state.workflowMltCount = action.payload.content
            state.pageable = {
                ...state.pageable,
                pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
            }
        },

        cacheWorklowPartsStatusFetched: (state, action) => {
            state.cacheWorklowPartsStatus = action.payload.content
            state.pageable = {
                ...state.pageable,
                pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
            }
        },

        workflowPaginationFetched: (state, action) => {
            state.workflowCountPagination = action.payload.content
            state.pageable = {
                ...state.pageable,
                pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
            }
        },
        dagFetchedById: (state, action) => {
            state.getSingleDag = action.payload
        },
        dagCountFetched:(state, action) => {
            state.getCounts = action.payload
        },
        kdenCountFetched:(state, action) => {
            state.getKdenCounts = action.payload
        },
        workflowFetched:(state, action) => {
            state.getSingleDag = action.payload
        },
    },
})

export default dagSlice