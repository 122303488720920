import { hideLoader, showLoader } from '../../../store/loader/actions'
import * as requestFromServer from './configCrud'
import configSlice from './configSlice'
import { store } from '../../../store'
const { actions } = configSlice

/* =========Get Config ========= */
export const getConfigListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchConfig()
      .then(response => {
        dispatch(actions.configFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Usecase Config found'
        reject(error)
      })
  })
}

export const configFetchedWithPagination = (query, search) => dispatch => {
  dispatch(showLoader()); 
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchConfigWithPagination(query, search)
      .then(response => {
        dispatch(actions.configPaginationFetched(response.data));
        resolve(response);
        dispatch(hideLoader()); 
      })
      .catch(error => {
        error.message = 'No Usecase Config found';
        reject(error);
        dispatch(hideLoader());
      });
  });
};



/* =========Create usecase config ========= */
export const createConfigAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .createConfig(data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        const errorMessage = error.response.data
        reject(errorMessage)
      })
  })
}

/* =========get single config ========= */
export const getSingleConfigAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchConfigById(id)
      .then(response => {
        dispatch(actions.configFetchedById(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Usecase Config found'
        reject(error)
      })
  })
}

/* =========Update config ========= */
export const updateConfigAction = (id, data) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .updateConfig(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Usecase Config found'
        reject(error)
      })
  })
}

/* =========Delete Config ========= */
export const DeleteConfigAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .deleteConfig(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        error.message = 'Not deleted Usecase Config'
        reject(error)
      })
  })
}

/* =========Delete Schema ========= */
export const DeleteSchemaAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .deleteSchema(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        error.message = 'Not deleted Schema'
        reject(error)
      })
  })
}

/* =========Upload usecase files usecase config ========= */
export const uploadUseCaseFilesAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .uploadUseCaseFiles(data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        const errorMessage = error.response.data
        reject(errorMessage)
      })
  })
}

/* =========Upload KDEN ========= */
export const uploadKdenAction =
  (data, options = {}) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .kdenUpload(data, (options = {}))

        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data
          reject(errorMessage)
        })
    })
  }

/* =========Create usecase video part workflow ========= */
export const createWorkflowAction =
  (videoConfig,data) => dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .createWorkflow(videoConfig,data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data
          reject(errorMessage)
        })
    })
  }

/* =========Get Mood list ========= */
export const getMoodListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchMood()
      .then(response => {
        dispatch(actions.moodListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Mood data found'
        reject(error)
      })
  })
}

/* =========Get Language ========= */
export const getLanguageListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchLanguage()
      .then(response => {
        dispatch(actions.languageListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Language data found'
        reject(error)
      })
  })
}

/* =========Get Pretrained Di net ========= */
export const getPretrainedDiNetListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchLipsyncPretrained()
      .then(response => {
        dispatch(actions.lipsyncPretrainedListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No  data found'
        reject(error)
      })
  })
}


/* =========Get Open face ========= */
export const getOpenFaceListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchLipsynOpenface()
      .then(response => {
        dispatch(actions.lipsyncOpenfaceListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No data found'
        reject(error)
      })
  })
}

/* =========Get Source Video path========= */
export const getSourceVideoPathListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchLipsyncSourceVideoPath()
      .then(response => {
        dispatch(actions.lipsyncSourceVideoListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No data found'
        reject(error)
      })
  })
}

/* =========Get Transliteration Type ========= */
export const getTransliterationTypeListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchTransliterationType()
      .then(response => {
        dispatch(actions.transliterationTypeListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Type data found'
        reject(error)
      })
  })
}

/* =========Get Style ========= */
export const getStyleListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchStyle()
      .then(response => {
        dispatch(actions.styleListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Style data found'
        reject(error)
      })
  })
}

/* =========Get Models ========= */
export const getModelsListAction = (gender,language) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchModels(gender,language)
      .then(response => {
        dispatch(actions.modelsListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Model data found'
        reject(error)
      })
  })
}


/* =========Get  VC Models ========= */
export const getVcModelsListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .fetchVcModel()
      .then(response => {
        dispatch(actions.vcModelsListFetched(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Vc Model data found'
        reject(error)
      })
  })
}


/* ========Download Csv ========= */
export const downloadKdenCsvAction = (id) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .downloadKdenCsv(id)
      .then(response => {
       
        resolve(response)
      })
      .catch(error => {
        error.message = 'No csv found'
        reject(error)
      })
  })
}

/* ========Change Config Status ========= */
export const ChangeConfigStatusAction = (id) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .ChangeConfigStatus(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const downloadUsecaseCsvAction =
  (id) => dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .downloadUsecaseCsv(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          error.message = 'No csv found'
          reject(error)
        })
    })
  }

  export const uploadConcatCsvAction =
  (Data) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .CsvUpload(Data)

        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data
          reject(errorMessage)
        })
    })
  }


  /* =========get final video count ========= */
export const getFinalVideosCount= useCaseName => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .getFinalVideosCount(useCaseName)
      .then(response => {
        dispatch(actions.getFinalVideosCount(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No count found'
        reject(error)
      })
  })
}


  /* =========Download Final Videos Csv ========= */
  export const downloadFinalVideosCsvAction = useCaseName => dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .downloadFinalVideosCsv(useCaseName)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data;
            reject(errorMessage);
        
        })  
    })
  }

  export const startUsecaseAction = id => dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .startUsecase(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data;
            reject(errorMessage);
        
        })  
    })
  }

  export const stopUsecaseAction = id => dispatch => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .stopUsecase(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data;
            reject(errorMessage);
        
        })  
    })
  }

  export const cacheTransliterationAction = data => {
    return new Promise((resolve, reject) => {
      requestFromServer
        .cacheTransliteration(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          const errorMessage = error.response.data;
            reject(errorMessage);
        
        })  
    })
  }



    /* =========get font names ========= */
export const getFontNamesAction= () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .getFontNames()
      .then(response => {
        dispatch(actions.getFonts(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No count found'
        reject(error)
      })
  })
}

export const workflowStateListingAction = (data) => dispatch => {
  dispatch(showLoader()); 
  return new Promise((resolve, reject) => {
    requestFromServer
      .workflowStateListing(data)
      .then(response => {
        dispatch(actions.getWorkflowState(response.data));
        resolve(response);
        dispatch(hideLoader()); 
      })
      .catch(error => {
        error.message = 'No Usecase Config found';
        reject(error);
        dispatch(hideLoader());
      });
  });
};

/* =========get single config ========= */
export const getWorkflowStateByIdAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .getWorkflowStateById(id)
      .then(response => {
        dispatch(actions.fetchWorkflowStateById(response.data))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No workflow found'
        reject(error)
      })
  })
}


export const DeleteWorkflowStateAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .deleteWorkflowState(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        error.message = 'Not deleted Usecase Config'
        reject(error)
      })
  })
}