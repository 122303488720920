import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SearchOutlined } from '@ant-design/icons'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { getPageable } from '../../../../../utils/helperFunctions'
import { connect } from 'react-redux'
import {
  getConfigListAction,
  configFetchedWithPagination,
  workflowStateListingAction,
  DeleteConfigAction,
  ChangeConfigStatusAction,
  downloadUsecaseCsvAction,
  uploadConcatCsvAction,
  getFinalVideosCount,
  downloadFinalVideosCsvAction,
  startUsecaseAction,
  stopUsecaseAction,
} from '../../../redux/configAction'
import WorkflowStateTable from './worklow-state-table'
import ConfigDelete from '../../../config-delete'

const useStyles = makeStyles(theme => ({
  searchBox: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    marginTop: '10px',
  },
}))

const WorkflowStateListing = ({ pageable, ...props }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedContent, setSelectedContent] = useState(null)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [isSubmitting, setIsSubmitting] = useState('')
  const [pagination, setPagination] = useState(() => getPageable(searchParams))

  const handleAssets = () => {
    navigate('/usecase-config/add')
  }

  const handleSearch = event => {
    setSearch(event.target.value)
  }

  const handleTableChange = (type, params) => {
    const queryParams = {
      page: params.page,
      size: params.rowsPerPage,
      sort: params.orderBy,
      order: params.order,
      search: search,
    }

    setPagination({
      pageNo: params.page,
      pageSize: params.rowsPerPage,
      sortBy: params.orderBy,
      sortDir: params.order,
      search: search,
    })

    navigate({
      pathname: '/usecase-config/list',
      search: `?${createSearchParams(queryParams)}`,
    })
  }

  useEffect(() => {
    setLoading(true)
    props
      .workflowStateListingAction(pagination, search)
      .then(resolve => {
        setLoading(false)
      })
      .catch(reject => {
        setLoading(false)
      })
  }, [searchParams, search])

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '100%',
              ml: { xs: 0, md: 1 },
              margin: 'auto',
              mt: '10px',
            }}
          >
            <Typography variant="h3" sx={{ fontFamily: 'Roboto, sans-serif' }}>
              Workflow State Listing
            </Typography>
          </Box>

          <Box className={classes.searchBox}>
            <OutlinedInput
              sx={{ backgroundColor: '#ffffff', width: '275px' }}
              size="medium"
              id="header-search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              endAdornment={
                search && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch('')}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
              aria-describedby="header-search-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              placeholder="Search by usecase name"
              value={search}
              onChange={handleSearch}
            />

            <Button
              variant="contained"
              onClick={handleAssets}
              sx={{ marginLeft: '20px' }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        </div>

        <Box
          sx={{
            width: '100%',
            mt: '20px',
          }}
        >
          <WorkflowStateTable
            pageable={pageable}
            getConfigList={props.getConfigList}
            ChangeConfigStatusAction={props.ChangeConfigStatusAction}
            loading={loading}
            setLoading={setLoading}
            setDeleteModal={setDeleteModal}
            setSelectedContent={setSelectedContent}
            handleTableChange={handleTableChange}
            downloadUsecaseCsvAction={props.downloadUsecaseCsvAction}
            uploadConcatCsvAction={props.uploadConcatCsvAction}
            getFinalVideosCount={props.getFinalVideosCount}
            downloadFinalVideosCsvAction={props.downloadFinalVideosCsvAction}
            startUsecaseAction={props.startUsecaseAction}
            stopUsecaseAction={props.stopUsecaseAction}
            getConfig={props.getConfig}
          />
        </Box>

        {deleteModal && (
          <ConfigDelete
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            pagination={pagination}
            search={search}
            selectedContent={selectedContent}
            setIsSubmitting={setIsSubmitting}
            isSubmitting={isSubmitting}
            configFetchedWithPagination={props.configFetchedWithPagination}
            workflowStateListingAction={props.workflowStateListingAction}
            DeleteConfigAction={props.DeleteConfigAction}
          />
        )}
      </Paper>
    </Grid>
  )
}

const mapStateToProps = state => ({
  getConfigList: state.usecaseconfig.configPagination,
  pageable: state.usecaseconfig.pageable,
  getConfig: state.usecaseconfig.getSingleConfig
})

const mapActionsToProps = {
  getConfigListAction,
  configFetchedWithPagination,
  workflowStateListingAction,
  DeleteConfigAction,
  ChangeConfigStatusAction,
  downloadUsecaseCsvAction,
  uploadConcatCsvAction,
  getFinalVideosCount,
  downloadFinalVideosCsvAction,
  startUsecaseAction,
  stopUsecaseAction,
}

export default connect(mapStateToProps, mapActionsToProps)(WorkflowStateListing)
