import {
  defaultPage,
  defaultSize,
  defaultSortBy,
  defaultSortDir,
  defaultTotalElements,
} from './paginationUtil'
import moment from 'moment'

export function sleep(time) {
  new Promise(acc => setTimeout(acc, time))
}

export function convertToCamel(str) {
  let outputStr = ''
  str?.split('_')?.forEach((item, index) => {
    if (index !== 0) outputStr += capitalizeFirstLetter(item?.toLowerCase())
    else outputStr += item.toLowerCase()
  })
  return outputStr
}
export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string.slice(1)
}

export function Time(str) {
  return str
    .replace(/ /g, '')
    .replace(/:/g, '')
    .replace(/\./g, '')
    .replace(/^0000/, '')
    .replace(/^00/, '')
}

export const secondsToTimecode = (time, fps) => {
  //2.784
  let hours = Math.floor(time / 3600) % 24
  let minutes = Math.floor(time / 60) % 60
  let seconds = Math.floor(time % 60)
  let frames = Math.floor(((time % 1) * fps).toFixed(3))
  let result =
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds) +
    ';' +
    (frames < 10 ? '0' + frames : frames)

  return result
}

export const milliSecondsToTimecode = (time, fps) => {
  //2.784
  time = parseFloat(time / 1000)
  let hours = Math.floor(time / 3600) % 24
  let minutes = Math.floor(time / 60) % 60
  let seconds = Math.floor(time % 60)
  let frames = Math.floor(((time % 1) * fps).toFixed(3))
  let result =
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds) +
    ';' +
    (frames < 10 ? '0' + frames : frames)

  return result
}

export const formatSecondsToTimecode = time => {
  //2.78476
  //time = parseFloat(time / 1000)
  let hours = Math.floor(time / 3600) % 24
  let minutes = Math.floor(time / 60) % 60
  let seconds = Math.floor(time % 60)
  let milliseconds = parseInt((time % 1).toFixed(3).split('.')[1])
  let result =
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds) +
    '.' +
    milliseconds

  return result
}

export const timecodeToMilliSeconds = (hh_mm_ss_ff, fps) => {
  var tc_array = hh_mm_ss_ff.replace(';', ':').split(':')
  var tc_hh = parseInt(tc_array[0])
  var tc_mm = parseInt(tc_array[1])
  var tc_ss = parseInt(tc_array[2])
  var tc_ff = parseInt(tc_array[3])
  var tc_in_seconds = tc_hh * 3600 + tc_mm * 60 + tc_ss + tc_ff / fps
  return parseInt(tc_in_seconds.toFixed(3) * 1000)
}

export const timecodeToSeconds = (hh_mm_ss_ff, fps) => {
  var tc_array = hh_mm_ss_ff.replace(';', ':').split(':')
  var tc_hh = parseInt(tc_array[0])
  var tc_mm = parseInt(tc_array[1])
  var tc_ss = parseInt(tc_array[2])
  var tc_ff = parseInt(tc_array[3])
  var tc_in_seconds = tc_hh * 3600 + tc_mm * 60 + tc_ss + tc_ff / fps
  return parseFloat(tc_in_seconds.toFixed(3))
}

export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return '0 Bytes'
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function secondsToHms(d) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export function timecodeToNumber(timecode) {
  const parts = timecode.split(':')
  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(parts[2], 10)
  const frames = parseInt(parts[3], 10)
  //const milliseconds=parseInt(frames/ 25 * 1000)
  return (
    hours.toString().padStart(2, '0') +
    minutes.toString().padStart(2, '0') +
    seconds.toString().padStart(2, '0')
    // milliseconds.toString().padStart(2, '0')
  )
}
export function secondsToTimeCode(seconds) {
  const padZero = number => {
    return number.toString().padStart(2, '0')
  }

  const currentFrame = Math.floor((seconds % 1) * 30)
  const currentMilliseconds = Math.floor((currentFrame / 25) * 1000)
  const currentSeconds = Math.floor(seconds % 60)
  const currentMinutes = Math.floor(seconds / 60) % 60
  const currentHours = Math.floor(seconds / 3600) % 24
  return `${padZero(currentHours)}:${padZero(currentMinutes)}:${padZero(
    currentSeconds
  )}:${padZero(currentFrame)}`
}

export function convertTimecode(timecode) {
  const hour = timecode.substr(0, 2)
  const minute = timecode.substr(2, 2)
  const second = timecode.substr(4, 2)
  const frame = timecode.substr(6, 2)
  return `${hour}:${minute}:${second}:${frame}`
}

export const convertArrayToObject = (arr = []) => {
  return arr.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
};

export const convertObjectToArray = (obj = {}) => {
  return Object.keys(obj).map(key => {
    return {
      key,
      value: obj[key],
    };
  }, {});
};


export  const isValidURL = mediaUrl => {
 
  if (!mediaUrl || mediaUrl?.url?.trim() === '') {
    return false
  }

  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '(([a-zA-Z\\d-]+\\.)+[a-zA-Z]{2,})' + // domain name
      '(\\/.*)?$', // optional path
    'i'
  )

  return urlPattern.test(mediaUrl)
}


export const formatDateTime = (date) => {
  return moment(date).format('MMMM D, YYYY h:mm A');
};

export function getPageable(searchParams) {
  return {
    pageNo: +searchParams.get('page') || defaultPage,
    totalElements: +searchParams.get('totalElements') || defaultTotalElements,
    pageSize: +searchParams.get('size') || defaultSize,
    sortBy: searchParams.get('sort') || defaultSortBy,
    sortDir: searchParams.get('order') || defaultSortDir,
    search: searchParams.get('search') || '',
  };
}

