import __env from '../../../env'
import instance from '../../../middleware/axiosInstance'
import axios from 'axios'

export const createDag = data => {
  return axios.post(`${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/`, data)
}

export const startDag = cacheId => {
  return axios.post(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/start/${cacheId}`
  )
}

export const stopDag = cacheId => {
  return axios.delete(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/stop/${cacheId}`
  )
}

export const deleteDag = cacheId => {
  return axios.delete(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/${cacheId}`
  )
}

export const fetchDag = () => {
  return axios.get(`${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/list/all`)
}

export const fetchDagWithPagination = (pageable, search) => {
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/all?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&searchBy=${search}`
  )
}

export const ChangeDagStatus = (id, workflowState) => {
  return instance.post(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/state/change/${id}?state=${workflowState}`
  )
}

export const downloadTlCsv = data => {
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/csv/cache-csv?fileName=${data?.['workflow-name']}&workflowId=${data['id']}`
  )
}

export const downloadOverlayCsv = (data ,usecaseId)=> {
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/csv/overlay-csv?workflowId=${data?.['id']}`
  )
}

export const getIterationCountStatus=(workflowId,page,itemsPerPage)=>{
  return instance.get(`${process.env.REACT_APP_CACHE_URL}/cache/iterations/mlAndMlt/page?page-number=${page}&page-size=${itemsPerPage}&sort-by=DESC&sort-dir=DESC&workflow-id=${workflowId}`)
}

export const getIterationMltCountStatus=(workflowId,page,itemsPerPage)=>{
  return instance.get(`${process.env.REACT_APP_CACHE_URL}/cache/iterations/mlt/page?page-number=${page}&page-size=${itemsPerPage}&sort-by=DESC&sort-dir=DESC&workflow-id=${workflowId}`)
}

export const cacheWorklowPartsStatus=(iterationId,responseType,pageable)=>{
  return instance.get(`${process.env.REACT_APP_CACHE_URL}/cache/iterations/cache/parts?page-number=${pageable.pageNo}&page-size=10&sort-by=${pageable.sortDir}&sort-dir=${pageable.sortDir}&iteration-id=${iterationId}&response-type=${responseType}`)
}


export const downloadMltCsv = data => {
  console.log("data",data)
  return axios.get(
    `${process.env.REACT_APP_SCHEMA_URL}/admin/csv/kden-csv?fileName=${data?.['workflow-name']}&workflowId=${data['id']}&useCaseId=${data['use-case-id']}`
  )
}

// export const dagCount = workflowName => {
//   return instance.get(
//    `${process.env.REACT_APP_CACHE_URL}/cache/get/count?workflowName=${workflowName}`
//   )
// }

// export const kdenCount = workflowName => {
//   return instance.get(
//    `${process.env.REACT_APP_CACHE_URL}/cache/get/count?workflowName=${workflowName}`
//   )
// }

export const dagCount = workflowName => {
  return instance.get(
   `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/details/${workflowName}`
  )
}

export const kdenCount = workflowName => {
  return instance.get(
   `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/details/${workflowName}`
  )
}

export const getWorkflowByName = workflowName => {
  return instance.get(
   `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/name/${workflowName}`
  )
}

export const addComments=(data,id)=>{
  return instance.post(
   `${process.env.REACT_APP_SCHEMA_URL}/admin/workflow/state/change/${id}`,data
  )
  }




