import { createSlice } from '@reduxjs/toolkit'
import {
    defaultPage,
    defaultSize,
    defaultSortDir,
    defaultTotalElements,
    defaultSortBy,
    defaultTotalPages,
} from '../../../constants/index'
import { workflowStateListing } from './configCrud'

const initialState = {
    getConfig: [],
    configPagination: [],
    getMoodList:[],
    getStyleList:[],
    getLanguageList:[],
    getPretrainedDiNetList:[],
    getOpenFaceList:[],
    getSourceVideoPath:[],
    getModelsList:[],
    vcModelList:[],
    getFontNames:[],
    getFinalVideosCount:null,
    getTransliterationTypeList:[],
    workflowStateListing:[],
    getSingleWorkflowState:null,
    pageable: {
        pageNo: defaultPage,
        pageSize: defaultSize,
        sortBy: defaultSortBy,
        sortDir: defaultSortDir,
        totalPages: defaultTotalPages,
        totalElements: defaultTotalElements,
    },
}

const configSlice = createSlice({
    name: 'usecaseconfig',
    initialState: initialState,
    reducers: {
        configFetched: (state, action) => {
            state.getConfig = action.payload
        },
        configPaginationFetched: (state, action) => {
            state.configPagination = action.payload.content
            state.pageable = {
                ...state.pageable,
                pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
            }
        },
        configFetchedById: (state, action) => {
            state.getSingleConfig = action.payload
        },

        moodListFetched: (state, action) => {
            state.getMoodList = action.payload
        },
        languageListFetched: (state, action) => {
            state.getLanguageList = action.payload
        },
        lipsyncPretrainedListFetched: (state, action) => {
            state.getPretrainedDiNetList = action.payload
        },
        lipsyncOpenfaceListFetched: (state, action) => {
            state.getOpenFaceList = action.payload
        },
        lipsyncSourceVideoListFetched: (state, action) => {
            state.getSourceVideoPath = action.payload
        },
        transliterationTypeListFetched: (state, action) => {
            state.getTransliterationTypeList = action.payload
        },
        styleListFetched: (state, action) => {
            state.getStyleList = action.payload
        },
        modelsListFetched: (state, action) => {
            state.getModelsList = action.payload
        },
        vcModelsListFetched:(state, action) => {
            state.vcModelList = action.payload
        },
        getFinalVideosCount: (state, action) => {
            state.getFinalVideosCount = action.payload
        },
        getFonts: (state, action) => {
            state.getFontNames = action.payload
        },
        getWorkflowState: (state, action) => {
            state.workflowStateListing = action.payload.content
            state.pageable = {
                ...state.pageable,
                pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
            }
        },
        fetchWorkflowStateById: (state, action) => {
            state.getSingleWorkflowState = action.payload
        },
        
    },
})

export default configSlice