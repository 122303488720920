import React, { useState, useMemo } from 'react'
import {
  Typography,
  Box,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  DialogTitle,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FileUploader } from 'react-drag-drop-files'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { formatDateTime } from '../../../../../../utils/helperFunctions'
import { toast } from 'react-toastify'
import MuiTable from '../../../../../../ui-components/mui-table/MuiTable'
import { saveAs } from 'file-saver'

import RefreshIcon from '@mui/icons-material/Refresh'
import { useNavigate } from 'react-router-dom'


const fileTypes = ['csv']
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="white"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const WorkflowStateTable = ({
  pageable,
  getConfigList,
  setDeleteModal,
  setSelectedContent,
  handleTableChange,
  downloadUsecaseCsvAction,
  uploadConcatCsvAction,
  loading,
  getFinalVideosCount,
  setLoading,
  downloadFinalVideosCsvAction,
  startUsecaseAction,
  stopUsecaseAction,

}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  
  const [uploadModal, setUploadModal] = useState(false)
  const [progress, setProgress] = useState(0)
  const [countModel, setCountModel] = useState(false)
  const [videoCount, setVideoCount] = useState(null)
  const [useCaseName, setUseCaseName] = useState('')
  const [isLoading,setIsLoading]=useState(false)
  const [usecaseState, setUsecaseState] = useState(false)


  const columns = useMemo(() => [
    
    {
      id: 'use-case-name',
      label: 'Usecase Name',
      align: 'left',
      alignHeader: 'left',
      formatter: row => (
        <a
          href={`/${row['use-case-id']}/workflow-status/details`}
          style={{
            color: theme.palette.secondary.main,
            textDecorationLine: 'underline',
            textUnderlineOffset: '.30em',
            textDecorationThickness: '0.8px',
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: theme.palette.secondary.main, fontWeight: 'inherit' }}
          >
            {row?.['use-case-name'] ? row?.['use-case-name'] : '--'}
          </Typography>
        </a>
      ),
    },

    {
      id: 'use-case-type',
      label: 'Usecase Type',
      align: 'left',
      alignHeader: 'left',
      formatter: row =>
        row['use-case-type']
          ?.replace('USE_CASE_TYPE_', '')
          ?.replace(/_+$/, ''),
    },

    {
      id: 'use-case-status',
      label: 'Status',
      align: 'left',
      alignHeader: 'left',
      formatter: row =>
        row['use-case-status']
          ?.replace('USE_CASE_STATUS_', '')
          ?.replace(/_+$/, ''),
    },

    {
      id: 'created-date',
      label: 'Added On',
      align: 'left',
      alignHeader: 'left',
      formatter: row => formatDateTime(row?.["created-date"]),
    },

    // {
    //   id: 'actions',
    //   label: 'Actions',
    //   align: 'center',
    //   alignHeader: 'center',
    //   formatter: ActionFormatter,
    //   formatExtraData: {
    //     onStart: item => handleStartUsecase(item),
    //     usecaseState:usecaseState,
    //     onStop: item => handleStopUsecase(item),
    //     onUpload: item => handleUploadDialog(item),
    //     onDownload: item => handleDownloadCsv(item),
    //     onEdit: item => handleEditConfig(item),
    //     onDownload: item => handleDownloadCsv(item),
    //     onCopy: item => handleCopyData(item),
    //     onDelete: item => handleDeleteConfigModel(item),
    //     onCount: item => handleCountModel(item),
    //     onDownloadFinalVideos:item => handleDownloadFinalVideos(item),
    //     onViewWorkflowStatus:item => handleViewWorkflowStatus(item),
    //   },
    // },
  ])


  const handleRefresh = () => {
         setIsLoading(true)
    getFinalVideosCount(useCaseName)
      .then(res => {
        setVideoCount(res?.data)
             setIsLoading(false)
      })
      .catch(error => {
          setIsLoading(false)
        console.error('Error fetching video count:', error)
      })
  }

  const handleUploadDialog = item => {
    setUploadModal(true)
  }

const handleViewWorkflowStatus=(item)=>{
  navigate(`/view/${item['use-case-id']}/workflow-status`)
}
  const handleFileUpload = file => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', file)
    uploadConcatCsvAction(formData, {
      onUploadProgress: event => {
        setProgress(Math.round((100 * event.loaded) / event.total))
      },
    }).then(response => {
      setProgress(0)
      setUploadModal(false)
      if (response.status === 202) {
        toast.success('Some data may not be uploaded ')
      } else {
        toast.success('File uploaded successfully')
      }
    })
    setLoading(false).catch(error => {
      setProgress(0)
      setUploadModal(false)
      toast.error('Failed to upload file')
    })
    setLoading(false)
  }

  const handleCopyData = item => {
    navigate(`/usecase-config/add?copyId=${item?.['use-case-id']}`)
  }

  const handleEditConfig = item => {
    navigate(`/usecase-config/${item['use-case-id']}/edit`)
  }

  const handleDeleteConfigModel = item => {
    setSelectedContent(item)
    setDeleteModal(true)
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mt: '40px',
        }}
      >
        <MuiTable
          columns={columns}
          records={getConfigList || []}
          showCheckbox={false}
          keyField={'id'}
          onTableChange={handleTableChange}
          onRowsSelect={selectedRows => {}}
          searchQuery=""
          remote={true}
          loading={loading}
          setLoading={setLoading}
          paginationProps={{
            totalCount: pageable?.totalElements,
            rowsPerPage: pageable?.pageSize,
            page: pageable?.pageNo,
            orderBy: pageable?.sortBy,
            order: pageable?.sortDir,
          }}
        />
      </Box>

      <Dialog
        open={uploadModal}
        onClose={() => setUploadModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DialogContentText>
            <FileUploader
              handleChange={file => handleFileUpload(file)}
              name="file"
              types={fileTypes}
            
            >
              <Stack
                spacing={4}
                justifyContent="center"
                alignItems="center"
                p={4}
              >
                <Box
                  sx={{
                    borderRadius: '50%',
                    backgroundColor: '#E5E5E5',
                    height: '70px',
                    width: '70px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FileUploadIcon sx={{ fontSize: '40px', color: '#AAA' }} />
                </Box>
                <Typography variant="h4" display="block">
                  Drag and drop to upload
                </Typography>

                <Button variant="contained" color="primary" disabled={loading}>
                  {progress > 0 ? (
                    <CircularProgressWithLabel
                      value={progress}
                      style={{ color: 'white' }}
                    />
                  ) : loading ? (
                    <CircularProgress />
                  ) : (
                    'Browse'
                  )}
                </Button>
              </Stack>
            </FileUploader>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={countModel}
        onClose={() => setCountModel(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            The count of videos is: {isLoading ? (
              <CircularProgress style={{ color: 'grey' }} size={20} />
            ) : (
              videoCount || 0
            )}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <Button variant="contained" onClick={handleRefresh}>
              <RefreshIcon/>Refresh
            </Button>
            <Button
              sx={{ ml: '20px' }}
              variant="outlined"
              onClick={() => setCountModel(false)}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}


export default WorkflowStateTable
