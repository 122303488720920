// assets
import TemplateIcon from '@mui/icons-material/Subscriptions'
import PubcliAssetIcon from '@mui/icons-material/VideoCameraFront'
import EventIcon from '@mui/icons-material/Event'
import PeopleIcon from '@mui/icons-material/People'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import CopyrightContentUpIcon from '@mui/icons-material/Person3'
import MyAssetIcon from '@mui/icons-material/VideoLibrary'
import DashboardIcon from '@mui/icons-material/Dashboard'
import OntololgyIcon from '@mui/icons-material/Category'
import IdentifierIcon from '@mui/icons-material/CardMembership'
import CampaignIcon from '@mui/icons-material/Campaign'
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SchemaIcon from '@mui/icons-material/Storage';
import ScenarioIcon from '@mui/icons-material/Assignment';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TranslateIcon from '@mui/icons-material/Translate'
import MicIcon from '@mui/icons-material/Mic'
import GraphicEqIcon from '@mui/icons-material/GraphicEq'
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';





// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  // title: 'Video Platform',
  // // caption: "Upload assets",
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      breadcrumbs: false,
    },

    // {
    //   id: 'templates',
    //   title: 'My Templates',
    //   type: 'item',
    //   url: '/templates/list',
    //   icon: TemplateIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'client',
    //   title: 'Client',
    //   type: 'item',
    //   url: '/customer/list',
    //   icon: PeopleIcon,
    //   breadcrumbs: false,
    // },


    {
      id: 'Global Cache',
      title: 'Global Cache',
      type: 'collapse',
      icon: PubcliAssetIcon,
      breadcrumbs: false,


      children: [

        {
          id: 'transliteration-service',
          title: 'Transliteration',
          type: 'item',
          icon: TranslateIcon,
          url: '/video-library/transliteration-service',
          target: false,
        },
        {
          id: 'textToSpeech',
          title: 'Text To Speech',
          type: 'item',
          icon: MicIcon,
          url: '/video-library/global/text-to-speech',
          target: false,
        }, 
      ],
    },

    {
      id: 'Template Cache',
      title: 'Template Cache',
      type: 'collapse',
      icon: FolderSpecialIcon,
      breadcrumbs: false,


      children: [

       
        {
          id: 'textToSpeech',
          title: 'Text To Speech',
          type: 'item',
          icon: MicIcon,
          url: '/video-library/template/text-to-speech',
          target: false,
        },

        {
          id: 'voiceClone',
          title: 'Voice Clone',
          type: 'item',
          icon: GraphicEqIcon,
          url: '/video-library/voice-cloned',
          target: false,
        },

        {
          id: 'lipSynced',
          title: 'Lip Sync',
          type: 'item',
          icon: FaceRetouchingNaturalIcon,
          url: '/video-library/lip-synced',
          target: false,
        },

        {
          id: 'cacheKden',
          title: 'Kden Videos',
          type: 'item',
          icon: VideoLibraryIcon,
          url: '/video-library/static/kden',
          target: false,
        },


      ],
    },
    {
      id: 'Media Planner',
      title: 'Media Planner',
      type: 'collapse',
      icon: ScenarioIcon,
      breadcrumbs: false,


      children: [
        {
          id: 'usecaseConfig',
          title: 'Usecase Onboarding',
          type: 'item',
          url: '/usecase-config/list',
          icon: PlayCircleIcon,
          breadcrumbs: false,
        },


        {
          id: 'dag',
          title: 'Workflow Manager',
          type: 'item',
          url: '/dag/list',
          icon: GroupWorkIcon,
          target: false,
        },

      ],
    },
    {
      id: 'finalVideos',
      title: 'Final Videos',
      type: 'item',
      icon: VideoLibraryIcon,
      url: '/video-library/usecase-output',
      breadcrumbs: false,
    },
    // {
    //   id: 'upcomingevents',
    //   title: 'Upcoming Events',
    //   type: 'item',
    //   url: '/upcoming/events',
    //   icon: EventIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'trendingtopics',
    //   title: 'Trending Topics',
    //   type: 'item',
    //   url: '/trending/topics',
    //   icon: TrendingUpIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'copyright content',
    //   title: ' Copyright Content',
    //   type: 'item',
    //   url: '/copyright-content/list',
    //   icon: CopyrightContentUpIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'publicAssets',
    //   title: 'Public Assets',
    //   type: 'collapse',
    //   icon: PubcliAssetIcon,
    //   breadcrumbs: false,

    //   children: [
    //     {
    //       id: 'publicVideo',
    //       title: 'Video',
    //       type: 'item',
    //       url: '/assets/list',
    //       target: false,
    //     },
    //   ],
    // },



    // {
    //   id: 'myAssets',
    //   title: 'My Assets',
    //   type: 'collapse',
    //   icon: MyAssetIcon,
    //   breadcrumbs: false,
    //   children: [
    //     {
    //       id: 'privateVideo',
    //       title: 'Video',
    //       type: 'item',
    //       url: '/myassets/list',
    //       target: false,
    //     },
    //     {
    //       id: 'privateImage',
    //       title: 'Image',
    //       type: 'item',
    //       url: '/image/list',
    //       target: false,
    //     },
    //     {
    //       id: 'privateFonts',
    //       title: 'Fonts',
    //       type: 'item',
    //       url: '/font/list',
    //       target: false,
    //     },
    //   ],
    // },


    // {
    //   id: 'myCampaigns',
    //   title: 'My Campaigns',
    //   type: 'item',
    //   url: '/campaigns/list',
    //   icon: CampaignIcon,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'identifier',
    //   title: 'My Identifiers',
    //   type: 'item',
    //   url: '/identifier/list',
    //   icon: IdentifierIcon,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'tags',
    //   title: 'Ontology',
    //   type: 'item',
    //   url: '/entity-tags/list',
    //   icon: OntololgyIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'tags',
    //   title: 'Composition Tags',
    //   type: 'item',
    //   url: '/composition-tags/list',
    //   icon: OntololgyIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'vendor',
    //   title: 'Vendor',
    //   type: 'item',
    //   url: '/vendor/list',
    //   icon: PeopleIcon,
    //   breadcrumbs: false,
    // },


    // {
    //   id: 'customer',
    //   title: 'Customer',
    //   type: 'item',
    //   url: '/customer/list',
    //   icon: PeopleIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'metadata',
    //   title: 'Metadata',
    //   type: 'item',
    //   url: '/metadata/list',
    //   icon: PeopleIcon,
    //   breadcrumbs: false,
    // },

    // {
    //   id: 'clientRequest',
    //   title: 'Client Request',
    //   type: 'item',
    //   url: '/client/list',
    //   icon: PeopleIcon,
    //   breadcrumbs: false,
    // },


    // {
    //       id: 'schema',
    //       title: 'Schema',
    //       type: 'item',
    //       url: '/schema/list',
    //       icon: SchemaIcon,
    //       breadcrumbs: false,
    //     },
    // {
    //   id: 'usecase',
    //   title: 'Usecase',
    //   type: 'item',
    //   url: '/usecase/list',
    //   icon: PeopleIcon,
    //   breadcrumbs: false,
    // },



    // {
    //   id: 'categories',
    //   title: 'Categories',
    //   type: 'item',
    //   url: '/category/list',
    //   icon: CategoryIcon,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'sub-category',
    //   title: 'SubCategories',
    //   type: 'item',
    //   url: '/subcategory/list',
    //   icon: CategoryIcon,
    //   breadcrumbs: false,
    // },
  ],
}

export default pages
